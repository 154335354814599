import MediaControlsComponent from '@wix/thunderbolt-elements/src/components/VideoBoxContainer/VideoBoxAudio/viewer/VideoBoxAudio';
import MediaControlsController from '@wix/thunderbolt-elements/src/components/VideoBoxContainer/VideoBoxAudio/viewer/VideoBoxAudio.controller';


const MediaControls = {
  component: MediaControlsComponent,
  controller: MediaControlsController
};


export const components = {
  ['MediaControls']: MediaControls
};


// temporary export
export const version = "1.0.0"
